<template>
  <section>
    <v-container>
      <router-view></router-view>
    </v-container>
  </section>
</template>

<script>
export default {
  name: 'index'
}
</script>

<style scoped>

</style>
